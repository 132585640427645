import React, { useEffect, useState } from 'react';
import "./Website/Website.css";
import Select from 'react-select';
import styled from 'styled-components';
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable } from './../data/Profile';
import { colors } from './../data/Colors';
import { clientApiGrid, contextMenuItems, countryList, customers, emailData, emailGrid, paymentGrid, paymentMethod, paymentReference, products } from './../data/champion';
import { GridComponent, ContextMenu, Edit, ExcelExport, Filter, Page, PdfExport, Resize, Sort, ColumnDirective, ColumnsDirective, Inject } from '@syncfusion/ej2-react-grids';
import { Header } from './../components';
import Selector from './../data/Selector';
import MultiSelector from './../data/MultiSelector';
import { Show } from '../data/Alerts';
import { apiServer } from '../data/Endpoint';
import { useNavigate } from 'react-router-dom';
import { AES, enc } from 'crypto-js';
import { Search, Toolbar } from '@syncfusion/ej2-react-grids';



const ClientApi = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const editing = { allowDeleting: true, allowEditing: true };
  

  const [continent, setContinent] = useState("")
  const [country, setCountry] = useState("")
  const [picture, setPicture] = useState("")
  const [name, setName] = useState("")
  const [location, setLocation] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [CustomersUserId,setCustomersUserId] = useState("")

  const [CustomerId, setCustomerId] = useState("")
  const [ApiHost, setApiHost] = useState("")
  const [ProductId, setProductId] = useState("")
  const [ApiServerURL, setApiServerURL] = useState("")



  const [CustomersList, setCustomersList] = useState([])



const navigate = useNavigate()

const [userInfo, setUserInfo] = useState({});

useEffect(() => {
 try{


   const encryptedData = sessionStorage.getItem("userDataEnc");
   const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
   const decryptedData = AES.decrypt(encryptedData, encryptionKey);
   const decryptedString = decryptedData.toString(enc.Utf8);
   const parsedData = JSON.parse(decryptedString);
     setUserInfo(parsedData);


 }catch(error){
  navigate("/")
 }

}, []);


useEffect(()=>{

  const formData = new FormData();
  formData.append("AdminId",userInfo.UserId)

fetch(apiServer+"ViewAllCustomers",{
  method: "POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
      body:formData
})
.then(res=>res.json())
.then(data=>{setCustomersList(data)
console.log(data)

})
.catch(err=>console.error(err))


},[userInfo])



const handleCreateClientApiServerURL= async () => {

 

Show.showLoading("Processing Data");
  try {

const formData = new FormData()
formData.append("UserId", CustomerId)
formData.append("ProductId", ProductId)
formData.append("apiHost", ApiHost)
formData.append("ApiServerURL", ApiServerURL)


formData.append("AdminId",userInfo.UserId)


    const response = await fetch(apiServer+"CreateClientApiServerURL", {
      method: "POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
      body:formData
    });

    const data = await response.json();
 

    if (response.ok) {
      
      Show.hideLoading();

      Show.Success(data.message);
       window.location.reload()
      
    } else {
      Show.Attention(data.message);
    }
  } catch (error) {

    Show.Attention("An error has occured");
   
  }

}


const handleUpdateClientApiServerURL = async () => {

 

Show.showLoading("Processing Data");
  try {

const formData = new FormData()
formData.append("CompanyId", CustomerId)
formData.append("ProductId", ProductId)
formData.append("ApiServerURL", ApiServerURL)

formData.append("AdminId",userInfo.UserId)


    const response = await fetch(apiServer+"UpdateClientApiServerURL", {
      method: "POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
      body:formData
    });

    const data = await response.json();
 

    if (response.ok) {
      
      Show.hideLoading();

      Show.Success(data.message);
       
      window.location.reload()
      
    } else {
      Show.Attention(data.message);
    }
  } catch (error) {

    Show.Attention("An error has occured");
   
  }

}

const handleDeleteClientApiServerURL = async (CompanyId) => {

 

  Show.showLoading("Processing Data");
    try {
  
  const formData = new FormData()
  formData.append("CompanyId", CompanyId)
    formData.append("AdminId",userInfo.UserId)


  
      const response = await fetch(apiServer+"DeleteClientApiServerURL", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,         
          'SessionId': userInfo.SessionId    
        },
        body:formData
      });
  
      const data = await response.json();
   
  
      if (response.ok) {
        
        Show.hideLoading();
  
        Show.Success(data.message);
         window.location.reload()
        
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
  
      Show.Attention("An error has occured");
     
    }
  
  }


 

  const handleSetUpActivation = async (updatedData) => {
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("apiHost", updatedData.apiHost);
      formData.append("apiKey", updatedData.apiKey);
      formData.append("apiSecret", updatedData.apiSecret);
      formData.append("email", updatedData.CompanyEmail);
      formData.append("companyName", updatedData.CompanyName);
      formData.append("companyId", updatedData.CompanyId);
      formData.append("companyPhone", updatedData.CompanyPhone);
      formData.append("productId", updatedData.productId);
      formData.append("packageType", updatedData.packageType);
      formData.append("softwareID", updatedData.softwareID);
      formData.append("AdminId", userInfo.UserId);
  
      const response = await fetch(updatedData.ApiServerURL+"api/audit/AppSetup", {  
        method: "POST",
        body: formData
      });

      console.log(formData)
  
      const data = await response.json();
  
      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      //Show.Attention("An error has occurred");
      Show.Attention(error.message);
    }
  };
  

  


  const handleActionBegin = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;
      handleSetUpActivation(updatedData);
    }

    if (args.requestType === 'delete') {
      const deletedData = args.data[0]; 
      handleDeleteClientApiServerURL(deletedData.CompanyId);
    }
  };


  const [ClientApiList, setClientApiList] = useState([])

  useEffect(()=>{
    fetch(apiServer+"ViewAllClientApiServerURL",{
      method:"POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
    })
    .then(res => res.json())
    .then(data => setClientApiList(data))
    .catch(error => console.error(error))
  },[userInfo])

  const [PriceList, setPriceList] = useState([])

        useEffect(()=>{
          fetch(apiServer+"ViewAllConfigurePackage",{
            method:"POST",
            headers: {
              'UserId': userInfo.UserId,         
              'SessionId': userInfo.SessionId    
            },
          })
          .then(res => res.json())
          .then(data => {
            // Process data to format Amount values to two decimal points
            const processedData = data.map(sale => ({
              ...sale,
              Amount: parseFloat(sale.Amount).toFixed(2) // Convert Amount to float and format to 2 decimal points
            }));
            setPriceList(processedData);
          })
          .catch(error => console.error(error));
        },[userInfo])
    




  return (
    <div>
      <Header category="Apps" title="ClientApi" />

      <div className="wwd-column">
        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Register Client API </div>

          <AdmitStudentRole>

    
          <Selector placeholder="Select Customer" dataList={CustomersList} dataKey="UserId" dataValue="Name" setMethod={(method) => setCustomerId(method)} />
          <MultiSelector placeholder="Select Product" dataList={PriceList} dataKey="ProductId" dataValue1="ProductName" dataValue2="PackageType" setMethod={(method) => setProductId(method)} />


            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Enter Domain</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setApiHost(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Enter ServerURL</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setApiServerURL(e.target.value)}
              />
            </div>


          </AdmitStudentRole>

          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={()=>{ handleCreateClientApiServerURL()}}       
            >Add
          </AdmitButton3>

          <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Enter Customer Id</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setCustomerId(e.target.value)}
              />
            </div>

            <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={()=>{ handleUpdateClientApiServerURL()}}       
            >Edit
          </AdmitButton3>

        </div>
      </div>







      <div style={{ marginTop: "2rem", padding: "1rem" }}>
        <span>
          <u
            style={{
              color: localStorage.getItem("colorMode"),
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
           Client Api List
          </u>
        </span>

        <GridComponent
           id="gridcomp"
      toolbar={['Search']}  // Add the search bar
 
          dataSource={ClientApiList}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          actionBegin={handleActionBegin}
          style={{ backgroundColor: localStorage.getItem("colorMode") }}
        >
          <ColumnsDirective>
            {clientApiGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport, Search, Toolbar]} />

        </GridComponent>
      </div>
    </div>
  );
}

export default ClientApi;
