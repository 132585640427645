import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { DashBoard, Calendar,  Editor } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import Hero from './pages/Website/Hero';
import WhatWeDo from './pages/Website/WhatWeDo';
import OurDifferences from './pages/Website/OurDifferences';
import OurProcess from './pages/Website/OurProcess';
import OurPortfolio from './pages/Website/OurPortfolio';
import OurClients from './pages/Website/OurClients';
import Testimonials from './pages/Website/Testimonials';
import Sales from './pages/Financials/Sales';
import Expenses from './pages/Financials/Expenses';
import Customers from './pages/UserMgmt/Customers';
import Employees from './pages/UserMgmt/Employees';
import InstantReply from './pages/Messages/InstantReply';
import FollowUp from './pages/Messages/FollowUp';
import MyProfile from './pages/Admin/MyProfile';
import Login from './pages/Homepage';
import Admin from './pages/UserMgmt/Admin';
import ClientApi from './pages/ClientApi';
import PriceConfiguration from './pages/Financials/PriceConfiguration';
import Visitors from './pages/Visitors';
import MainChat from './pages/MainChat';
import ManualPayment from './pages/Financials/ManualPayment';
import TestDashBoard from './pages/TestDashBoard';
import Partners from './pages/UserMgmt/Partners';
import Step1 from './pages/OnBoarding/Step1';
import Step2 from './pages/OnBoarding/Step2';
import Step3 from './pages/OnBoarding/Step3';
import Step4 from './pages/OnBoarding/Step4';
import Clerk from './pages/Apps/Clerk';
import HydotCall from './pages/Apps/HydotCall';
import ScheduleMeeting from './pages/Apps/ScheduleMeeting';
import OnBoarded from './pages/OnBoarding/OnBoarded';
import DeBoarded from './pages/OnBoarding/DeBoarded';
import Resources from './pages/Resources/Resources';
import ResourceList from './pages/Resources/ResourceList';
import BulkEmail from './pages/Resources/BulkEmail';
import OfficialEmails from './pages/Apps/OfficialEmails';
import ScheduleEmail from './pages/Apps/ScheduleEmail';
import PricePerDay from './pages/Financials/PricePerDay';

const MainPage = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const navigate = useNavigate()

  useEffect(() => {
    let activityTimer;
  
    const clearSessionAndNavigate = () => {
      sessionStorage.clear();
    navigate("/")

    };
  
    const resetTimer = () => {
      clearTimeout(activityTimer);
  
      activityTimer = setTimeout(clearSessionAndNavigate, 6000000); 
    };
  
    const resetTimerOnActivity = () => {
      if (activityTimer) {
        clearTimeout(activityTimer);
      }
      resetTimer();
    };
  
    // Initial setup and event listeners
    resetTimer();
    window.addEventListener('mousemove', resetTimerOnActivity);
    window.addEventListener('keypress', resetTimerOnActivity);
  
    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', resetTimerOnActivity);
      window.removeEventListener('keypress', resetTimerOnActivity);
      clearTimeout(activityTimer);
    };
  }, [navigate]);


  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      
      <div>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>

            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}

              <Routes>
                {/* dashboard  */}
                <Route path="DashBoard" element={(<DashBoard/>)} />

                {/* User Management  */}
                <Route path="employees" element={<Employees />} />
                <Route path="customers" element={<Customers />} />
                <Route path="admin" element={<Admin />} />
                <Route path="partners" element={<Partners />} />

                {/* MainPages  */}
              
                <Route path="calendar" element={<Calendar />} />
                <Route path="hero" element={<Hero />} />
                <Route path="WhatWeDo" element={<WhatWeDo />} />
                <Route path="OurDifferences" element={<OurDifferences />} />
                <Route path="OurProcess" element={<OurProcess />} />
                <Route path="OurPortfolio" element={<OurPortfolio />} />
                <Route path="OurClients" element={<OurClients />} />
                <Route path="Testimonials" element={<Testimonials />} />
                <Route path="sales" element={<Sales />} />
                <Route path="manualPayment" element={<ManualPayment />} />
                <Route path="expenses" element={<Expenses />} />
                <Route path="instantReply/:Id" element={<InstantReply />} />
                <Route path="allMessages" element={<FollowUp />} />
                <Route path="myProfile" element={<MyProfile />} />
                <Route path="clientApi" element={<ClientApi />} />
                <Route path="priceConfiguration" element={<PriceConfiguration />} />
                <Route path="visitors" element={<Visitors />} />
                <Route path="mainChat" element={<MainChat />} />
                <Route path="pricePerDay" element={<PricePerDay />} />

                 {/* Onboard  */}
                 <Route path="step1" element={<Step1 />} />
                 <Route path="step2" element={<Step2 />} />
                 <Route path="step3" element={<Step3 />} />
                 <Route path="step4" element={<Step4 />} />
                 <Route path="onBoarded" element={<OnBoarded />} />
                 <Route path="deBoarded" element={<DeBoarded />} />

                  {/* APPS  */}
                  <Route path="clerk" element={<Clerk />} />
                  <Route path="hydotCall" element={<HydotCall />} />
                  <Route path="scheduleCall" element={<ScheduleMeeting />} />
                  <Route path="scheduleEmail" element={<ScheduleEmail />} />
                  <Route path="officialEmail" element={<OfficialEmails />} />

                   {/* Resources  */}
                   <Route path="resources" element={<Resources />} />
                   <Route path="resourceList" element={<ResourceList />} />
                   <Route path="bulkEmail" element={<BulkEmail />} />

                   

                <Route path="*" element={<Navigate to="/" />} />
                

               
               
               

              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainPage;
